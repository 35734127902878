import moment from "moment";

jQuery( document ).ready(function($) {
$('.switch').on('click', function(e){
  e.preventDefault();
  $('#bg').removeClass();
  $('#main section').hide();
  let target = $(this).attr('href');
  console.log(target);
  $('#bg').addClass(target.substring(1))
  $(target).fadeIn();
  $('nav').removeClass('open');
})

$('.menu-toggle').on('click', function(e){
  e.preventDefault();
  $('nav').toggleClass('open');
});

//Newsletter
$('.newsletter').on('submit', function(e) {
  e.preventDefault();
  const DATA = $(this).serialize();

  $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
          withCredentials: false,
      },
      success: function (data) {
          $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
          console.log(err);
      }
  });
});

// tour
$.ajax({
    url: 'https://rest.bandsintown.com/artists/Dylan%20Marlowe/events?app_id=45PRESS_DYLAN_MARLOWE',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#events');
      let html = '';
      if (data.length) {
        for (let event of data) {
          let location = event.venue.city + ', ' + event.venue.region;
          if (location === ', ') { location = ''; }
          if (event.venue.name.toLowerCase() === 'streaming live') { location = 'Online'; }
          html += '<div class="event">';
          html += '<div class="event-date">' + moment(event.datetime).format('D MMM') + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '<div class="event-location">' + location + '</div>';
          html += '<div class="event-links">';
          if (event.offers[0]) {
            html += '<a href="' + event.offers[0].url + '" target="_blank" class="btn btn-yellow">' + event.offers[0].type + '</a>';
          }
          if (event.offers[1]) {
            html += '<a href="' + event.offers[1].url + '" target="_blank" class="btn btn-yellow">' + event.offers[1].type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('No upcoming events.');
      }
    }
  });
  $('.all-dates').click(function (e) {
    e.preventDefault()
    $('#events').toggleClass('all')
    let currentText = $(this).html()
    if (currentText === 'view all dates') {
      $(this).html('view less dates')
    } else {
      $(this).html('view all dates')
    }
  })

  
  $('#main section:not(#home)').hide();

  if(window.location.hash) {
    $('#bg').removeClass();
    $('#main section').hide();
    let target = window.location.hash;
    console.log(target);
    $('#bg').addClass(target.substring(1))
    $(target).fadeIn();
    $('nav').removeClass('open');
  }
});